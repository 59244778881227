import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import './invoice.css'
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')

const InvoiceBody = ({saleData, isDetailedView = true}) => {
    const classes = useStyles();
    let [sale, saleSet] = useState([])
    let [saleDetails, saleDetailsSet] = useState([])

    useEffect(() => {
        saleSet(saleData)
        if(saleData.details != undefined) {
            saleDetailsSet(sortByItemCode(saleData.details))
        }
    }, [saleData])

    // Helper function to extract code number from product name
    const getItemCode = (prodName) => {
        const codeMatch = prodName.match(/\d{4}$/);
        return codeMatch ? parseInt(codeMatch[0]) : 9999; // Default high number for items without codes
    };

    // Sort function for detailed view
    const sortByItemCode = (details) => {
        return [...details].sort((a, b) => {
            return getItemCode(a.prod_name) - getItemCode(b.prod_name);
        });
    };

    const groupSimilarItems = (details) => {
        // First group by code
        const groupedItems = details.reduce((acc, item) => {
            const codeMatch = item.prod_name.match(/\d{4}$/);
            if (!codeMatch) return acc;
            
            const codeNumber = codeMatch[0];
            const groupKey = `WO-${codeNumber}`;
            
            if (!acc[groupKey]) {
                acc[groupKey] = {
                    prod_name: `Item ${groupKey}`,
                    sale_qty: 0,
                    prod_unit_name: item.prod_unit_name,
                    sale_rate: item.sale_rate,
                    sale_prod_total: 0,
                    items_count: 0,
                    code: parseInt(codeNumber) // Store the numeric code for sorting
                };
            }
            
            acc[groupKey].sale_qty += parseFloat(item.sale_qty);
            acc[groupKey].sale_prod_total += parseFloat(item.sale_prod_total);
            acc[groupKey].items_count += 1;
            
            return acc;
        }, {});

        // Convert to array and sort by code number
        let sortedItems = Object.values(groupedItems)
            .sort((a, b) => a.code - b.code);

        // If we have too many items, further consolidate
        const MAX_ITEMS = 15; // Adjust this number based on your A4 size
        if (sortedItems.length > MAX_ITEMS) {
            const mainItems = sortedItems.slice(0, MAX_ITEMS - 1);
            const othersGroup = sortedItems.slice(MAX_ITEMS - 1).reduce((acc, item) => {
                acc.sale_qty += item.sale_qty;
                acc.sale_prod_total += item.sale_prod_total;
                acc.items_count += item.items_count;
                return acc;
            }, {
                prod_name: 'Other Items',
                sale_qty: 0,
                prod_unit_name: sortedItems[0].prod_unit_name,
                sale_rate: 0,
                sale_prod_total: 0,
                items_count: 0,
                code: 9999 // Give "Other Items" the highest code number
            });

            // Calculate average rate for others group
            othersGroup.sale_rate = othersGroup.sale_prod_total / othersGroup.sale_qty;
            
            sortedItems = [...mainItems, othersGroup];
        }

        return sortedItems;
    };

    const displayItems = isDetailedView ? saleDetails : groupSimilarItems(saleDetails);

    return (
        <>
            <table className={`invoice-table ${!isDetailedView ? 'simple-view' : ''}`}> 
                <thead>
                    <tr>
                        <td style={{textAlign:'left'}}>SL</td>
                        <td style={{textAlign:'left'}}>Description</td>
                        <td>Qty</td>
                        <td>Rate</td>
                        <td>Total</td>
                    </tr>
                </thead>
                <tbody>
                    {displayItems.map((detail, ind) => (
                        <tr key={ind}>
                            <td style={{textAlign:'left'}}>{ind + 1}</td>
                            <td style={{textAlign:'left'}}>
                                {detail.prod_name}
                                {!isDetailedView && detail.items_count > 1 && 
                                    <span className="item-count">({detail.items_count} variants)</span>
                                }
                            </td>
                            <td>{detail.sale_qty} {detail.prod_unit_name}</td>
                            <td>{format(parseFloat(detail.sale_rate).toFixed(2))} </td>
                            <td style={{textAlign:'right'}}>{format(parseFloat(detail.sale_prod_total).toFixed(2))}</td>
                        </tr>
                    ))}

                    <tr>
                        <td style={{textAlign:'left'}}></td>
                        <td style={{textAlign:'left'}}></td>
                        <td>{displayItems.reduce((prev, curr) => {
                            return prev + parseFloat(curr.sale_qty)
                        }, 0).toFixed(2)} </td>
                        <td></td>
                        <td style={{textAlign:'right'}}></td>
                    </tr>
                </tbody>
            </table>
        </>
    )
}

const useStyles = makeStyles((theme) => ({}));

export default InvoiceBody;