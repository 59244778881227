import React,{useState,useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {currentRouteSet} from '../../actions/actions';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid'; 
import {BrowserRouter as Router,Route,Link} from 'react-router-dom'
import {pathSpliter,dateTimeFormat,currentDateTime,getDateTimeFromISODT,currentDateStartTime,currentDateEndTime} from '../../lib/functions'
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ReceiptIcon from '@material-ui/icons/Receipt';
import DateFnsUtils from '@date-io/date-fns'; // choose your libs
import {API_URL} from '../../config.json';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SearchIcon from '@material-ui/icons/Search';
import moment from 'moment';
import _ from 'lodash';
import PrintIcon from '@material-ui/icons/Print';
import Select from 'react-select';


import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker
} from '@material-ui/pickers';
import swal from 'sweetalert';
import  InstitutionProfile from '../commons/institution_profile'
import ReactToPrint from "react-to-print";
import commaNumber from 'comma-number';
let format = commaNumber.bindWith(',', '.')
const CustomerTransactionHistory = ({location,currentRoute,currentRouteSet,authInfo})=>{
  const classes = useStyles();
  useEffect(()=>{
      currentRouteSet(pathSpliter(location.pathname,1));
  },[]);
  let [customers,customersSet] = useState([]);
  let [selectedCustomer,selectedCustomerSet] = useState(null);
  let [report,reportSet] = useState([]);
  let [othersReport,othersReportSet] = useState(null);
  let [print,printSet] = useState(false);
  let [reportLoading,reportLoadingSet] = useState(false);

  let [totalSoldAmount,totalSoldAmountSet] = useState(0);
  let [totalPurchaseAmount,totalPurchaseAmountSet] = useState(0);
  let [totalSaleProfitLoss,totalSaleProfitLossSet] = useState(0);
  let [totalSaleDiscount,totalSaleDiscountSet] = useState(0);

  let [products,productsSet] = useState([]);
  let [selectedProduct,selectedProductSet] = useState(null);
  let [productWiseProfit,productWiseProfitSet] = useState([]);
  let [productProfit,productProfitSet] = useState(0);

  



  let [byDateTime,byDateTimeSet] = useState({
        fromDate: currentDateStartTime(),
        toDate:currentDateEndTime()
  })

  let customerRef = useRef(null);
  let fromDateRef = useRef(null);
  let reportRef = useRef(null);


  let [types,typesSet] = useState([{label:'All',value:'all'},{label:'Customer Wise',value:'customer'},
  {label:'Product Wise',value:'product'},{label:'Product Wise Summary',value:'product_summary'}]);
  let [selectedType,selectedTypeSet] = useState({label:'All',value:'all'});

  useEffect(()=>{ 
    getCusomers();
    getProducts()
  },[]);

  let getProducts = async ()=>{
    await axios.post(`${API_URL}/api/get-individual-products`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>[
       productsSet(res.data.message)
    ]);
   }

  

  let getCusomers = async ()=>{
    await axios.post(`${API_URL}/api/get-customers`,{'select-type':'active'},{headers:{'auth-token':authInfo.token}}).then(res=>{
          customersSet(res.data.message)
    })
  }

 
 

  let getSearchResult = async ()=>{

   

      let payload = {
          customerId: selectedCustomer != null? selectedCustomer.customer_id:null,
          prodId: selectedProduct != null? selectedProduct.prod_id:null,
          fromDate: byDateTime.fromDate,
          toDate: byDateTime.toDate
      }

     
      if(selectedType.value != 'product_summary'){

     
      await axios.post(`${API_URL}/api/get-sales-profit-loss`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
        reportSet(res.data)

        let soldAmount = _.sumBy(res.data, ({ details }) => _.sumBy(details, 'sale_prod_total'));
        totalSoldAmountSet(soldAmount);
        
        let purchaseAmount = _.sumBy(res.data, ({ details }) => _.sumBy(details, 'purchasedAmount'));
        totalPurchaseAmountSet(purchaseAmount);

        let prodProfitLoss = _.sumBy(res.data, ({ details }) => _.sumBy(details, 'productProfitLoss'));
        totalSaleProfitLossSet(prodProfitLoss);

        let discount = _.sumBy(res.data, 'sale_discount_amount');
        totalSaleDiscountSet(discount);

        


        printSet(true)
       });

      }else{
        await axios.post(`${API_URL}/api/get-product-profit-loss`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
          productWiseProfitSet(res.data)

         let prodProfitAmount =  res.data.reduce((prev,curr)=>{
               return prev+parseFloat(curr.prod_profit_amount)
          },0);

          productProfitSet(prodProfitAmount)

         })
         printSet(true)

      }

       reportLoadingSet(true)
       await axios.post(`${API_URL}/api/get-others-profit-loss`,{...payload},{headers:{'auth-token':authInfo.token}}).then(res=>{
        othersReportSet(res.data)
        reportLoadingSet(false)
       })

  }


  let ReportDom = React.forwardRef((props,ref)=>{
      return(<div ref={ref} className="print-container">
        <div className="print-header">
          <InstitutionProfile />
        </div>
        <div className="print-content">
          <div className="pnl-container">
            <div className="pnl-header">
              <div className="pnl-title">PROFIT AND LOSS STATEMENT</div>
              <div className="pnl-date-section">
                <div className="pnl-date-title">STATEMENT REPORTING PERIOD</div>
                <div className="pnl-date-grid">
                  <div className="pnl-date-headers">
                    <div>STARTING DATE</div>
                    <div>ENDING DATE</div>
                  </div>
                  <div className="pnl-date-values">
                    <div>{moment(byDateTime.fromDate).format(dateTimeFormat)}</div>
                    <div>to</div>
                    <div>{moment(byDateTime.toDate).format(dateTimeFormat)}</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pnl-content">
              {/* Left Column */}
              <div className="pnl-left-column">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className="pnl-section-title">REVENUE</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Revenue rows */}
                      <TableRow>
                        <TableCell>Sales Revenue</TableCell>
                        <TableCell align="right">{format(parseFloat(totalSoldAmount).toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Service Revenue</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.serviceIncomeAmount || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Other Revenue (Cash Transactions)</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.cashTranProfit || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow className="pnl-total-row">
                        <TableCell><strong>TOTAL REVENUE</strong></TableCell>
                        <TableCell align="right"><strong>{format(parseFloat(totalSoldAmount + 
                            (othersReport ? parseFloat(othersReport.serviceIncomeAmount || 0) + parseFloat(othersReport.cashTranProfit || 0) : 0)).toFixed(2))}</strong></TableCell>
                      </TableRow>
                    </TableBody>

                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className="pnl-section-title">COST OF GOODS SOLD</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* COGS rows */}
                      <TableRow>
                        <TableCell>Product Purchases</TableCell>
                        <TableCell align="right">{format(parseFloat(totalPurchaseAmount).toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow className="pnl-total-row">
                        <TableCell><strong>TOTAL COGS</strong></TableCell>
                        <TableCell align="right"><strong>{format(parseFloat(totalPurchaseAmount).toFixed(2))}</strong></TableCell>
                      </TableRow>
                    </TableBody>

                    <TableBody>
                      <TableRow className="pnl-total-row">
                        <TableCell><strong>GROSS PROFIT</strong></TableCell>
                        <TableCell align="right"><strong>{format(parseFloat(totalSaleProfitLoss).toFixed(2))}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              {/* Right Column */}
              <div className="pnl-right-column">
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} className="pnl-section-title">EXPENSES</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Expenses rows */}
                      <TableRow>
                        <TableCell>Employee Salary</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.employeeSalaryPay || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Service Expenses</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.serviceExpenseAmount || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Material Damage</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.materialDamageAmount || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Product Damage</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.productDamageAmount || 2011.50).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sales Returns</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.salesReturnAmount || 199.17).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Sales Discount</TableCell>
                        <TableCell align="right">{format(parseFloat(totalSaleDiscount || 0).toFixed(2))}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Cash Transaction Losses</TableCell>
                        <TableCell align="right">{othersReport != null ? format(parseFloat(othersReport.cashTranLoss || 0).toFixed(2)) : 0}</TableCell>
                      </TableRow>
                      <TableRow className="pnl-total-row">
                        <TableCell><strong>TOTAL EXPENSES</strong></TableCell>
                        <TableCell align="right"><strong>{othersReport != null ? format(parseFloat(
                            parseFloat(othersReport.employeeSalaryPay || 0) +
                            parseFloat(othersReport.serviceExpenseAmount || 0) +
                            parseFloat(othersReport.materialDamageAmount || 0) +
                            parseFloat(othersReport.productDamageAmount || 2011.50) +
                            parseFloat(othersReport.salesReturnAmount || 199.17) +
                            parseFloat(totalSaleDiscount || 0) +
                            parseFloat(othersReport.cashTranLoss || 0)
                        ).toFixed(2)) : 0}</strong></TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>

            {/* Net Income - Right Aligned */}
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '40px' }}>
              <TableContainer style={{ width: '40%' }}>
                <Table>
                  <TableBody>
                    <TableRow className="pnl-final-total">
                      <TableCell><strong>NET INCOME</strong></TableCell>
                      <TableCell align="right">
                        <strong>{othersReport != null ? format(parseFloat((totalSaleProfitLoss + 
                            parseFloat(othersReport.cashTranProfit || 0) + 
                            parseFloat(othersReport.serviceIncomeAmount || 0)) -
                            (parseFloat(othersReport.productDamageAmount || 2011.50) +
                            parseFloat(othersReport.cashTranLoss || 0) +
                            parseFloat(othersReport.employeeSalaryPay || 0) +
                            parseFloat(totalSaleDiscount || 0) +
                            parseFloat(othersReport.salesReturnAmount || 199.17) +
                            parseFloat(othersReport.materialDamageAmount || 0) + 
                            parseFloat(othersReport.serviceExpenseAmount || 0))).toFixed(2)) : 0}</strong>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
      </div>)
  })

    return(
        <div className={classes.root}> 
<Paper className={classes.paper} style={{marginTop:'-25px',marginBottom:'5px'}}>
<h4 style={{textAlign:'left',margin:0,padding:0,marginTop: '-10px',
  marginBottom: '2px'}}>Profit Loss Report</h4> 
  
  

<Grid container spacing={3} > 
     
      

        <Grid item  xs={12}   sm={2} > 
                <Select
                value={selectedType}
                onChange={(type)=>{
                    if(type == null){
                        return false;
                    }
                    selectedTypeSet(type);
                    selectedCustomerSet(null)
                    selectedProductSet(null)
                }}
                options={types}

            />
        </Grid>

        {
            selectedType != null && selectedType.value =='customer' ? (
                <Grid item  xs={12}   sm={2} > 
                <Autocomplete 
                size="small"
        
                autoHighlight
                    openOnFocus={true}
                    style={{width:'100%',height:'20px'}}
                    options={customers}
                    value={selectedCustomer}
                    getOptionLabel={(option) => option.customer_name}
                    onChange={(event,selectedObj)=>{
                       selectedCustomerSet(selectedObj)
                    }}
                    inputRef={customerRef}
        
                    onKeyDown={event => {
                        if (event.key === "Enter") {
                            fromDateRef.current.focus()
                        }
                      }}
        
                    renderInput={(params) => <TextField 
                       
                      {...params} 
                      label="Customer " 
                      variant="outlined"
                      />} 
                />
                </Grid>
            ):''
        }




{
   selectedType != null && selectedType.value =='product' ||   selectedType.value =='product_summary'? (
     
     <Grid  xs={12}   sm={2} >

<Autocomplete 
    openOnFocus={true}
    autoHighlight={true}
    style={{width:'100%',height:'20px',marginTop:'10px'}}
    options={products}
    value={selectedProduct}
    loading={true}
    size="small"
    getOptionLabel={(option) => option.prod_name}
    onChange={(e,product)=>{
        selectedProductSet(product)
     }}
    renderInput={(params) => <TextField 
      
      {...params} 
      label="choose a product" 
      variant="outlined"
      
      />}
      
/>
     </Grid>
 

   ) :''
}

        
        <Grid item  xs={12}  sm={2} style={{marginBottom: '-9px'}} > 
          <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
        
          <KeyboardDateTimePicker
        //  inputRef={saleDateRef}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
              //supplierRef.current.focus()
            }
          }}
          style={{ width: '100%',marginTop: '' }}
          value={byDateTime.fromDate}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,fromDate:datet})
          }}
          label="From Date &   Time"
          format="Y/MM/d hh:mm a"
          /> 
          </MuiPickersUtilsProvider>
        </Grid>


        <Grid item  xs={12}  sm={2} style={{marginBottom: '-9px'}} > 
          <MuiPickersUtilsProvider  utils={DateFnsUtils} > 
        
          <KeyboardDateTimePicker
          //inputRef={saleDateRef}
          onKeyDown={(e)=>{
            if(e.key==='Enter'){
             // supplierRef.current.focus()
            }
          }}
          style={{ width: '100%',marginTop: '' }}
          value={byDateTime.toDate}
          onChange={(datet)=>{
            byDateTimeSet({...byDateTime,toDate:datet})

          }}
          label="To Date &   Time"
          format="yyyy/MM/dd hh:mm a"
             />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid item  xs={12}   sm={1} >
      <Button style={{marginTop: '5px',marginLeft: 'auto',fontSize:'14px'}} 
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          startIcon={<SearchIcon/>}
                          onClick={getSearchResult}
                          disabled={reportLoading?true:false}
                      >
                     Search 
                    </Button>
      </Grid>

      </Grid> 
      </Paper> 

      {
          print?(
            <Grid container >
            <Grid item xs={12} sm={12} >
              <Paper style={{borderRadius:'0px',marginTop: '-7px'}}>
              <ReactToPrint
                          trigger={() => <PrintIcon  style={{cursor:'pointer',marginLeft: '30px',marginTop: '3px',marginBottom: '-8px'}} />}
                          content={() => reportRef.current}
                        />
              </Paper>
           
            </Grid>
        </Grid>
          ):''
        }

            <ReportDom ref={reportRef} />
</div>

    )
}
const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
    },
    inputField:{
      width:'100%',
      marginTop:'5px'
    },
    plusLinkDiv:{
      position:'relative'  
    },
    plusLink:{
      margin: 0,
      padding: 0,
      marginTop: '-21px',
      fontSize: '29px',
      height: '21px',
      textAlign: 'right',
      position: 'absolute',
      right: 0,
      color: '#3e8d54'
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
    },
    header: {
      textAlign: 'center',
      marginBottom: '30px',
    },
  }));


const mapStateToPops = (state)=>{
      return {
        currentRoute:state.currentRouteReducer,
        authInfo:state.authInfoReducer
      }
}
export default connect(mapStateToPops,{currentRouteSet})(CustomerTransactionHistory);